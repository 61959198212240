import axios from '../axios';

function upload(formData) {
  return axios.post('/api/media_objects', formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'multipart/form-data',
    },
  })
    // get data
    .then((response) => response.data);
  // add url field
  /* .then(x => x.map(img => Object.assign({},
      img, { url: `/api/media_objects/1` }))
    ); */
}

function getImage(file) {
  return new Promise((resolve, reject) => {
    const fReader = new FileReader();
    const img = document.createElement('img');

    fReader.onload = () => {
      img.src = fReader.result;
      if (img.src == null) {
        reject(reject);
      }
      resolve(getBase64Image(img));
    };

    fReader.readAsDataURL(file);
  });
}

function getBase64Image(img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL('image/png');

  return dataURL;
}

export { upload, getImage, getBase64Image };
